import React from "react";
import PropTypes from "prop-types";
import CryptoJS from "crypto-js";
import { connect } from "react-redux";
import { BeatLoader } from "react-spinners";
import * as config from "configure/configServer.js";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Description from "@material-ui/icons/Description";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// import redux action
import toggleSurvey from "actions/survey/toggleSurvey.js";
import surveyType from "actions/survey/setSurveyType.js";
import surveyModal from "actions/survey/surveyModal.js";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

// switch language function
import switchIntl from "../../configure/switchIntl.js";

import surveyImg1 from "assets/img/card_house.jpg";
import surveyImg2 from "assets/img/card_condo.jpg";
import surveyImg3 from "assets/img/card_villa.jpg";
import GHBankLoading from "assets/img/reicLoading.gif";

import numeral from "numeral";

class GHDashboard extends React.Component {
  state = {
    value: 0,
    token: null,
    loadingScreen: true,
    proj_all: 0,
    proj_pass: 0,
    proj_eject: 0,
    proj_wait: 0,
    proj_save: 0,
  };
  static propTypes = {
    toggleLng: PropTypes.string.isRequired,
    surveyType: PropTypes.string.isRequired,
    toggleSurvey: PropTypes.string.isRequired,
    isToggle: PropTypes.bool.isRequired,
    surveytype: PropTypes.string.isRequired,
    surveyModal: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.getSecretKey();
  }

  getSecretKey() {
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("sessionP");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);
          this.getall_Psurveyporject(jsonDec.token);
        }
      })
      .catch(() => {
        this.toggleModal("error");
      });
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  getall_Psurveyporject(token) {
    var request = new Request(config.API_listall_psurvey, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: token,
      }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          const data = responseJson.data.filter((d) => d.condo_approved !== 5);

          const grouped = this.groupBy(data, (data) => data.condo_approved);

          this.setState({
            proj_all: data.length,
            proj_pass: grouped.get(1) === undefined ? 0 : grouped.get(1).length,
            proj_eject:
              grouped.get(2) === undefined ? 0 : grouped.get(2).length,
            proj_wait: grouped.get(3) === undefined ? 0 : grouped.get(3).length,
            proj_save: grouped.get(4) === undefined ? 0 : grouped.get(4).length,
            loadingScreen: false,
          });
        } else {
          this.setState({
            loadingScreen: false,
          });
        }
      })
      .catch(() => {
        this.toggleModal("error");
      });
  }

  toggleModal(type) {
    this.props.surveyModal(
      <SweetAlert
        type="error"
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={
          type === "save" ? "ไม่สามารถบันทึกเอกสารได้" : "ไม่สามารถไปต่อได้"
        }
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      >
        {type === "save"
          ? "กรุณาตรวจสอบเลขที่แบบสอบถาม และ กรอกข้อมูลเอกสารที่จำเป็นให้ครบถ้วนก่อนทำการบันทึก"
          : "ไปต่อไม่ได้"}
      </SweetAlert>
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 1500);
  }

  handleChange = (value) => {
    this.setState({ value });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  openSurvey(type) {
    this.props.surveyType(type);
    this.props.toggleSurvey(true);
    setTimeout(() => {
      // eslint-disable-next-line react/prop-types
      this.props.history.push("/survey");
    }, 500);
  }

  loadingScreen(isLoading) {
    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.3)",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99999,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 250,
              height: 250,
              backgroundColor: "#fff",
              borderRadius: 10,
            }}
          >
            <img src={GHBankLoading} height="100%" alt="ghlogo" />
          </div>
        </div>
      );
    } else {
      //
    }
  }

  render() {
    const { classes } = this.props;

    const loadingCard = (
      <div className="card_loading">
        <BeatLoader
          sizeUnit={"px"}
          size={15}
          color={"#ffffff"}
          loading={true}
        />
      </div>
    );

    return (
      <div>
        {this.loadingScreen(this.state.loadingScreen)}

        <h3 style={{ marginTop: "-1%" }}>
          {switchIntl(this.props.toggleLng).pSurveyDashboard.title}
        </h3>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <p
            style={{
              fontSize: "20px",
              marginTop: "2%",
              marginBottom: "20px",
            }}
          >
            สถานะแบบสอบถามของคุณ ทั้งหมด{" "}
            {numeral(this.state.proj_all).format("0,0")} โครงการ
          </p>
        </GridItem>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={3}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Description />
                </CardIcon>
                <p className={classes.cardCategory}>
                  {switchIntl(this.props.toggleLng).pSurveyDashboard.card_1}
                </p>
                <h3 className={classes.cardTitle}>
                  {numeral(this.state.proj_save).format("0,0")}
                </h3>
              </CardHeader>
              <CardFooter></CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Description />
                </CardIcon>
                <p className={classes.cardCategory}>
                  {switchIntl(this.props.toggleLng).pSurveyDashboard.card_2}
                </p>
                <h3 className={classes.cardTitle}>
                  {numeral(this.state.proj_wait).format("0,0")}
                </h3>
              </CardHeader>
              <CardFooter></CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Description />
                </CardIcon>
                <p className={classes.cardCategory}>
                  {switchIntl(this.props.toggleLng).pSurveyDashboard.card_3}
                </p>
                <h3 className={classes.cardTitle}>
                  {numeral(this.state.proj_pass).format("0,0")}
                </h3>
              </CardHeader>
              <CardFooter></CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={3}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <Description />
                </CardIcon>
                <p className={classes.cardCategory}>
                  {switchIntl(this.props.toggleLng).pSurveyDashboard.card_4}
                </p>
                <h3 className={classes.cardTitle}>
                  {numeral(this.state.proj_eject).format("0,0")}
                </h3>
              </CardHeader>
              <CardFooter></CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardBody>
                <div className={classes.stats} style={{ fontSize: "16px" }}>
                  {switchIntl(this.props.toggleLng).pSurveyDashboard.card_5}
                </div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={4}>
                    <Card>
                      {this.props.isToggle &&
                      this.props.surveytype === "household"
                        ? loadingCard
                        : null}
                      <a
                        href="#/"
                        onClick={() =>
                          this.props.isToggle
                            ? null
                            : this.openSurvey("household")
                        }
                      >
                        <CardBody className="card_imageBody">
                          <img
                            src={surveyImg1}
                            alt="..."
                            className="card_imagePic"
                          />
                        </CardBody>
                        <CardFooter stats className="card_imageFooter">
                          <div
                            className={classes.stats}
                            style={{ fontSize: "12px" }}
                          >
                            Code: H
                          </div>
                          <div
                            className={classes.stats}
                            style={{ fontSize: "14px" }}
                          >
                            {
                              switchIntl(this.props.toggleLng).pSurveyDashboard
                                .card_5_codeH
                            }
                          </div>
                        </CardFooter>
                      </a>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={4}>
                    <Card>
                      {this.props.isToggle && this.props.surveytype === "condo"
                        ? loadingCard
                        : null}
                      <a
                        href="#/"
                        onClick={() =>
                          this.props.isToggle ? null : this.openSurvey("condo")
                        }
                      >
                        <CardBody className="card_imageBody">
                          <img
                            src={surveyImg2}
                            alt="..."
                            className="card_imagePic"
                          />
                        </CardBody>
                        <CardFooter stats className="card_imageFooter">
                          <div
                            className={classes.stats}
                            style={{ fontSize: "12px" }}
                          >
                            Code: C
                          </div>
                          <div
                            className={classes.stats}
                            style={{ fontSize: "14px" }}
                          >
                            {
                              switchIntl(this.props.toggleLng).pSurveyDashboard
                                .card_5_codeC
                            }
                          </div>
                        </CardFooter>
                      </a>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={4}>
                    <Card>
                      {this.props.isToggle && this.props.surveytype === "villa"
                        ? loadingCard
                        : null}
                      <a
                        href="#/"
                        onClick={() =>
                          this.props.isToggle ? null : this.openSurvey("villa")
                        }
                      >
                        <CardBody className="card_imageBody">
                          <img
                            src={surveyImg3}
                            alt="..."
                            className="card_imagePic"
                          />
                        </CardBody>
                        <CardFooter stats className="card_imageFooter">
                          <div
                            className={classes.stats}
                            style={{ fontSize: "12px" }}
                          >
                            Code: V
                          </div>
                          <div
                            className={classes.stats}
                            style={{ fontSize: "14px" }}
                          >
                            {
                              switchIntl(this.props.toggleLng).pSurveyDashboard
                                .card_5_codeV
                            }
                          </div>
                        </CardFooter>
                      </a>
                    </Card>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

GHDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  toggleSurvey: (payload) => dispatch(toggleSurvey(payload)),
  surveyType: (payload) => dispatch(surveyType(payload)),
  surveyModal: (payload) => dispatch(surveyModal(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(GHDashboard));
