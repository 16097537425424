export const requestUrl = "https://psurvey.reicth.com";

// export const requestUrl = "https://psurvey.powermap.live";

export const recaptchaKey = "6LfiqSIaAAAAALsQMD4TjeB2avy9-BmSwZGf6DhX";

const sessionP = localStorage.getItem("sessionP storage");
export const checkSession = sessionP === null ? false : true;

// map api
// export const heremap_API_key = "4jd6f0fj_g_ZcmzhZAH3sVpHTO5AJBYQAInKFQjRgss";
export const heremap_API_key = "JV7MDgWAOuzAXCWKYSVYFrpT-AnaTWMbwVPb9788HIA";
export const heremap_appID = "W336uytfRUG9kKud1fnj";
export const heremap_appCode = "eYy0bLdL7xizsFDZkBfJGQ";

// server.js
export const API_sendevent = requestUrl + "/api/sendevent";
export const API_addex_image = requestUrl + "/api/addexhouseimg";
export const API_submit_file = requestUrl + "/api/addfile";
export const API_add_image = requestUrl + "/api/addimg";
export const API_addOrg = requestUrl + "/api/addorg";
export const API_submit_condo = requestUrl + "/api/addpcondo";
export const API_submit_household = requestUrl + "/api/addphousehold";
export const API_submit_villa = requestUrl + "/api/addpvilla";
export const API_bbox_address = requestUrl + "/api/bbox_cwt";
export const API_get_cate_house = requestUrl + "/api/cat_house";
export const API_get_cate_villa = requestUrl + "/api/cat_villa";
export const API_checkrefcode = requestUrl + "/api/chkrefcode";
export const API_delpsurvey = requestUrl + "/api/delpsurvey";
export const API_easypassword = requestUrl + "/api/easytoguesspassword";
export const API_exptoken = requestUrl + "/api/exptoken";
export const API_FAQ = requestUrl + "/api/faqs";
export const API_genprojid = requestUrl + "/api/genprojid";
export const API_gen_secretkey = requestUrl + "/api/genskey";
export const API_getprice = requestUrl + "/api/getprice";
export const API_get_prov = requestUrl + "/api/getprov";
export const API_get_guild = requestUrl + "/api/listguild";
export const API_listall_psurvey = requestUrl + "/api/listpsurvey";
export const API_login = requestUrl + "/api/login";
export const API_logout = requestUrl + "/api/logout";
export const API_getold_resetpasswd = requestUrl + "/api/oldresetpassword";
export const API_showdetail_projectid_condo = requestUrl + "/api/pcondodetail";
export const API_showdetail_projectid_household =
  requestUrl + "/api/phousedetail";
export const API_get_pointOnMap = requestUrl + "/api/point_psurvey";
export const API_address_code = requestUrl + "/api/point_tambon";
export const API_profile = requestUrl + "/api/profile";
export const API_contact_us = requestUrl + "/api/psurveycontact";
export const API_showdetail_projectid_villa = requestUrl + "/api/pvilladetail";
export const API_register = requestUrl + "/api/register";
export const API_no_project = requestUrl + "/api/report_psurvey";
export const API_save_condo = requestUrl + "/api/savepcondo";
export const API_save_household = requestUrl + "/api/savephousehold";
export const API_save_villa = requestUrl + "/api/savepvilla";
export const API_search_oldproject = requestUrl + "/api/searcholdprojid";
export const API_get_org = requestUrl + "/api/searchorg";
export const API_get_orgreg = requestUrl + "/api/searchregorg";
export const API_sendresetpassword = requestUrl + "/api/v1/sendresetpassword";
export const API_updateprofile = requestUrl + "/api/updateprofile";
